import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import pick from 'lodash/pick';
import PageHeader from '../generic/PageHeader';
import AzKeyboard from '../generic/aToZ/AzKeyboard';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import { promoShape } from '../promo/shapes';
import Pagination from '../generic/pagination/Pagination';
import PaginationSummary from '../generic/pagination/PaginationSummary';
import MetaProperties from '../generic/MetaProperties';

export const Page = ({
  baseURL,
  letter,
  page,
  pages,
  promos,
  azCounts,
  totalCount,
  metaDescription,
}) => (
  <div className="az-page">
    <MetaProperties
      title={`Collections | ${letter.toUpperCase()}`}
      description={metaDescription}
      canonical={`${baseURL}/food/collections`}
    />
    <Helmet>
      {page > 1 && (
        <link rel="prev" href={`${baseURL}/food/collections/a-z/${letter}/${page - 1}`} />
      )}
      {page < pages && (
        <link rel="next" href={`${baseURL}/food/collections/a-z/${letter}/${page + 1}`} />
      )}
    </Helmet>
    <PageHeader className="index__title" title="Collections" />
    <AzKeyboard
      baseUrl={`${baseURL}/food/collections`}
      selected={letter}
      azCounts={azCounts}
      anchor="featured-content"
    />
    <PaginationSummary page={page} totalCount={totalCount}>
      collections
    </PaginationSummary>
    {promos && (
      <StandardCollection12Promos
        id={`az-collections-${letter}`}
        promos={promos}
        containerClassName="az-page"
        maxCollectionSize={24}
      />
    )}
    <Pagination
      page={page}
      pages={Math.ceil(totalCount / 24)}
      buildUrl={p => `/food/collections/a-z/${letter}/${p}#featured-content`}
    />
  </div>
);

Page.defaultProps = {
  page: 1,
  pages: 1,
  promos: [],
  azCounts: {},
};

Page.propTypes = {
  baseURL: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  page: PropTypes.number,
  pages: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  totalCount: PropTypes.number.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default connect(state => ({
  baseURL: state.pageReducer.baseURL,
  ...pick(state.azPageReducer, [
    'letter',
    'page',
    'pages',
    'promos',
    'totalCount',
    'azCounts',
    'metaDescription',
  ]),
}))(Page);
